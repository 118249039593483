import {
  CInput,
  CLabel,
  CRow,
  CCol,
  CButton,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react";
import { Satellite, Delete, ArrowForward, Person } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import { MapApi } from "src/Config/API";
import opticalIcon from "../../../../../../assets/mapsicons/opticalrec.svg";
import opticalpassive from "../../../../../../assets/mapsicons/orpassive.svg";
import swal from "sweetalert";

import Customers from "../../../../testdata/optical_reciver/customers";

export function CustomeORShow({
  data,
  MapKey,
  getMasteData,
  mapDataFinal,
  handleImageView,
  DeleteDataByID,
  forftth,
  type,
}) {
  const [InputData, setInputData] = useState("");
  const [InputImage, setInputImage] = useState(null);
  const [InputSwapCheck, setInputSwapCheck] = useState(false);

  const [CustomerID, setCustomerID] = useState("");
  useEffect(() => {
    setInputData(data.name);
  }, [data]);
  const [ShowModalForCustomer, setShowModalForCustomer] = useState(false);

  function handleShowCustomerModal() {
    setShowModalForCustomer(!ShowModalForCustomer);
  }

  function EditOpticalReceiver(id, InputValue, InputImage, InputSwapCheck) {
    const form = new FormData();
    form.append("name", InputValue);
    form.append("core_swap", InputSwapCheck);

    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        swal("Saved!", "Information saved successfully!", "success");

        getMasteData();
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.success === false) {
          swal({
            title: "Warning!",
            text: error.response.data.msg,
            icon: "warning",
            button: "Okay!",
          });
        }
      });
  }

  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "/" +
            lat +
            "," +
            lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  return (
    <>


      {forftth ? <>

        <CCard>
          <CCardHeader> <center>
            <h4>
              {type === "orp" ? "Optical Reciver Passive" : "Optical Reciver"}
            </h4>
          </center></CCardHeader>
          <CCardBody>
            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                        <></>
                      ) : (
                        <>
                          <CLabel style={{ margin: 10, color: "blue" }}>
                            CATV
                          </CLabel>
                          <tr style={{ fontSize: 12 }}>
                            <td>
                              {" "}
                              <center>
                                <b>1550NM</b>
                                <br />
                                Optical Power
                              </center>
                            </td>
                            <td>
                              {" "}
                              <div class="input-box-DB">
                                <CInput
                                  value={data.loss_1550NM}
                                // placeholder="Joint Number"
                                // mask="99.99"
                                ></CInput>
                                <span class="prefix">DB</span>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                      {mapDataFinal.port.wdm_status === 1 ? (
                        <>
                          <CLabel style={{ margin: 10, color: "blue" }}>
                            INTERNET
                          </CLabel>

                          <tr style={{ fontSize: 12 }}>
                            <td>
                              {" "}
                              <center>
                                <b>1490NM</b>
                                <br />
                                Optical Power
                              </center>
                            </td>
                            <td>
                              {" "}
                              <div class="input-box-DB">
                                <CInput
                                  value={data.loss_1490NM}
                                // placeholder="Joint Number"
                                // mask="99.99"
                                ></CInput>
                                <span class="prefix">DB</span>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </CTabPane>
                <CTabPane data-tab="other_info">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Optical Power</b>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            onChange={(v) => setInputData(v.target.value)}
                            placeholder="Enter Optical Power"
                            value={InputData}
                          ></CInput>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                fontsize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Upload Image{" "}
                              {data.images !== null ? (
                                <Satellite
                                  onClick={
                                    () => handleImageView()
                                    // handleImageView(
                                    //   data.images
                                    // )
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </p>
                          </p>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            type="file"
                            name="file"
                            accept="image/*"
                            capture="camera"
                            onChange={(v) => setInputImage(v.target.files[0])}
                          ></CInput>
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                  <table class="table table-bordered table-sm">
                    <tbody>
                    <tr>
                        <td>
                          <CButton
                            onClick={() => {
                              handleShowCustomerModal();
                              setCustomerID(data.id);
                            }}
                            style={{ fontSize: 15 }}
                            type="button"
                            block
                            color="info"
                            variant="outline"
                          >
                            Customer
                          </CButton>
                        </td>
                        <td>  <CButton
                          onClick={() =>
                            EditOpticalReceiver(
                              data.id,
                              InputData,
                              InputImage,
                              InputSwapCheck ? 1 : 0
                            )
                          }
                          color="primary"
                          block
                        // variant="outline"
                        >
                          {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                          Save{" "}
                        </CButton></td>
                        <td> <CButton
                          onClick={() => DeleteDataByID(data.id)}
                          color="info"
                          block
                          variant="outline"
                        >
                          <Delete color="#ff0000" style={{ color: "red" }} />
                        </CButton></td>
                      </tr>
                      </tbody>
                      </table>
                  <div>
                  </div>
                </CTabPane>
              </CTabContent>
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                    Loss{" "}
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>
            {data.images !== null ?   <CButton color="primary" block style={{marginTop:10}} onClick={()=>handleImageView()}>
                  Show Image
                    &nbsp;<Satellite
                      onClick={
                        () => handleImageView()
                        // handleImageView(
                        //   data.images
                        // )
                      }
                    />
                
                </CButton>
                : 
                  <></> }
          </CCardBody>
        </CCard>
      </> : <Marker
        position={[parseFloat(data.latlang.lat), parseFloat(data.latlang.lang)]}
        icon={
          type === "orp"
            ? L.icon({
              iconUrl: opticalpassive,
              iconRetinaUrl: opticalpassive,
              iconAnchor: [5, 55],
              popupAnchor: [10, -44],
              iconSize: [60, 90],
            })
            : L.icon({
              iconUrl: opticalIcon,
              iconRetinaUrl: opticalIcon,
              iconAnchor: [5, 55],
              popupAnchor: [10, -44],
              iconSize: [60, 90],
            })
        }
      >
        <>
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputData}</center>

              <img
                src={data.images}
                width="200"
                height={"200"}
              />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputData}</center>
            </Tooltip>
          )}
        </>
        <Popup minWidth={300} closeButton={false}>
          <center>
            <h4>
              {type === "orp" ? "Optical Reciver Passive" : "Optical Reciver"}
            </h4>
          </center>

          <CTabs activeTab="loss">
            <CTabContent>
              <CTabPane data-tab="loss">
                <table class="table table-bordered table-sm">
                  <tbody>
                    {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                      <></>
                    ) : (
                      <>
                        <CLabel style={{ margin: 10, color: "blue" }}>
                          CATV
                        </CLabel>
                        <tr style={{ fontSize: 12 }}>
                          <td>
                            {" "}
                            <center>
                              <b>1550NM</b>
                              <br />
                              Optical Power
                            </center>
                          </td>
                          <td>
                            {" "}
                            <div class="input-box-DB">
                              <CInput
                                value={data.loss_1550NM}
                              // placeholder="Joint Number"
                              // mask="99.99"
                              ></CInput>
                              <span class="prefix">DB</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                    {mapDataFinal.port.wdm_status === 1 ? (
                      <>
                        <CLabel style={{ margin: 10, color: "blue" }}>
                          INTERNET
                        </CLabel>

                        <tr style={{ fontSize: 12 }}>
                          <td>
                            {" "}
                            <center>
                              <b>1490NM</b>
                              <br />
                              Optical Power
                            </center>
                          </td>
                          <td>
                            {" "}
                            <div class="input-box-DB">
                              <CInput
                                value={data.loss_1490NM}
                              // placeholder="Joint Number"
                              // mask="99.99"
                              ></CInput>
                              <span class="prefix">DB</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </CTabPane>
              <CTabPane data-tab="other_info">
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <b>Optical Power</b>
                      </td>
                      <td>
                        {" "}
                        <CInput
                          onChange={(v) => setInputData(v.target.value)}
                          placeholder="Enter Optical Power"
                          value={InputData}
                        ></CInput>
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>
                        {" "}
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Upload Image{" "}
                            {data.images !== null ? (
                              <Satellite
                                onClick={
                                  () => handleImageView()
                                  // handleImageView(
                                  //   data.images
                                  // )
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </p>
                      </td>
                      <td>
                        {" "}
                        <CInput
                          type="file"
                          name="file"
                          accept="image/*"
                          capture="camera"
                          onChange={(v) => setInputImage(v.target.files[0])}
                        ></CInput>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CButton
                          onClick={() => {
                            handleShowCustomerModal();
                            setCustomerID(data.id);
                          }}
                          style={{ fontSize: 15 }}
                          type="button"
                          block
                          color="info"
                          variant="outline"
                        >
                          Customer
                        </CButton>
                      </td>
                      <td>  <CButton
                        onClick={() =>
                          EditOpticalReceiver(
                            data.id,
                            InputData,
                            InputImage,
                            InputSwapCheck ? 1 : 0
                          )
                        }
                        color="primary"
                        block
                      // variant="outline"
                      >
                        {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                        Save{" "}
                      </CButton></td>
                      <td> <CButton
                        onClick={() => DeleteDataByID(data.id)}
                        color="info"
                        block
                        variant="outline"
                      >
                        <Delete color="#ff0000" style={{ color: "red" }} />
                      </CButton></td>
                    </tr>
                  </tbody>
                </table>
                <div>
                </div>
              </CTabPane>
            </CTabContent>
            <CNav variant="tabs" justified="center">
              <CNavItem>
                <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                  Loss{" "}
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  data-tab="other_info"
                  style={{ fontFamily: "sans-serif" }}
                >
                  Other Info
                </CNavLink>
              </CNavItem>
            </CNav>
          </CTabs>
          <br />
          <CRow>
            <CCol>
              <CButton
                variant="outline"
                color="primary"
                block
                onClick={() => {
                  getMyLocation({
                    lat: parseFloat(mapDataFinal.latlang.lat),
                    lang: parseFloat(mapDataFinal.latlang.lang),
                  });
                  setLoadingModalShowForDirectionOpenMap(true);
                }}
              >
                {LoadingModalShowForDirectionOpenMap
                  ? "Wait Loading..."
                  : "Get Location"}{" "}
                {/* <ArrowForward /> */}
              </CButton>
              <br />
              {getlatforcurruntLocation !== "" ? (
                <center>
                  {" "}
                  <a
                    href={
                      "https://www.google.com/maps/dir/" +
                      getlatforcurruntLocation +
                      "," +
                      getlangforcurruntLocation +
                      "/" +
                      parseFloat(data.latlang.lat) +
                      "," +
                      parseFloat(data.latlang.lang)
                    }
                    target="_BLANK"
                  >
                    Get Directon <ArrowForward />
                  </a>
                </center>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
        </Popup>
      </Marker>}

      <Customers
        id={CustomerID}
        ShowModal={ShowModalForCustomer}
        close={() => setShowModalForCustomer(!ShowModalForCustomer)}
        MapKey={MapKey}
      />
    </>
  );
}

// <CTabs activeTab="loss">
// <CTabContent>
//   <CTabPane data-tab="loss"></CTabPane>
//   <CTabPane data-tab="other_info"></CTabPane>
// </CTabContent>
// <CNav variant="tabs" justified="center">
//   <CNavItem>
//     <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
//       Loss{" "}
//     </CNavLink>
//   </CNavItem>

//   <CNavItem>
//     <CNavLink
//       data-tab="other_info"
//       style={{ fontFamily: "sans-serif" }}
//     >
//       Other Info
//     </CNavLink>
//   </CNavItem>
// </CNav>
// </CTabs>
