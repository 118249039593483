import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CImg,
  CTooltip,
  CLabel,
  CInput,
  CSwitch,
  CRow,
  CCol,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { DeviceApi, ProfileApi } from "src/Config/API";
// routes config
import routes from "../routes";
import jointIcon from "../assets/mapsicons/joint.svg";

import {
  TheHeaderDropdown,
  TheHeaderDropdownSetting,
  TheHeaderDropDownDevices,
} from "./index";
import TheHeaderShowCustomerList from "./TheHeaderShowCustomerList";
import DeviceTipsDrawer from "src/views/Tips/DeviceTipsDrawer";
import { ArrowBackIos, ArrowBackIosOutlined, ArrowBackIosRounded, BackspaceRounded, BarChart, Fullscreen, Home, Refresh, Search, SearchRounded } from "@material-ui/icons";
import MapTips from "src/views/Tips/MapTips";
import TransmitterMapTips from "src/views/Tips/TransmitterTips";

const TheHeaderPlanScreen = (
  {
    mapFunctionallity,
    couplorfun,
    onufun,
    ornfun,
    plcfun,
    jointfun,
    loopfun,
    orfun,
    orpfun,
    fttxfun,
    checkPolyLines,
    setcheckPolyLines,
    cs,
    onu,
    orn,
    joints,
    ls,
    ors,
    orps,
    fttx,
    ps,
    mapdevicename,
    DeviceID,
    portNumber,
    RefreshCustomerListFun,
    portID,
    drawNav,
    title,
    onclickSerach,
    navigtionroutesname,
    isfttx
  }
) => {
  const history = useHistory();
  const actions = [
    { icon: "C", name: "Coupler", onClick: () => couplorfun(), img: { jointIcon }, show: cs },
    { icon: "J", name: "Joint", onClick: () => jointfun(), img: { jointIcon }, show: joints },
    { icon: "PLC", name: "PLC Splitter", onClick: () => plcfun(), img: { jointIcon }, show: ps },
    { icon: "L", name: "Loop", onClick: () => loopfun(), img: { jointIcon }, show: ls },
    { icon: "ONU", name: "Onu", onClick: () => onufun(), img: { onufun }, show: onu },
    { icon: "ORN", name: "Optical Reciver Node", onClick: () => ornfun(), img: { jointIcon }, show: orn },
    {
      icon: "OR",
      name: "Optical Receiver Active",
      onClick: () => orfun()
      , img: { jointIcon }, show: ors
    },
    {
      icon: "OR-P",
      name: "Optical Receiver Passive",
      onClick: () => orpfun()
      , img: { jointIcon }, show: orps
    },
    {
      icon: "FTTx",
      name: "FTTx",
      onClick: () => fttxfun()
      , img: { jointIcon }, show: fttx
    },
    // {
    //   icon: "MR",
    //   name: "Marker Enable Disable",
    //   onClick: () => mrfun(),
    // },
  ];
  const [firstName, setfirstName] = useState("");
  const [PortData, setPortData] = useState(null);
  const [portName,setportName]=useState("");
  useEffect(() => {
    ProfileApi.getPrfileData()
      .then((response) => {
        // console.log(response.data.user);
        setfirstName(response.data.user.full_name);
      })
      .catch((error) => { });
    if (DeviceID !== 0) {
      console.log(DeviceID);
      DeviceApi.getdevicesDataIndivitual(mapdevicename, DeviceID)
        .then(function (response) {
          setPortData(response.data.data);
        })
        .catch(function (error) {
        });
    }

  }, []);
  const logout = () => {
    /* eslint-disable */
    const toLogout = confirm("Are you sure to logout ?");
    /* eslint-enable */
    if (toLogout) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userData");
      window.localStorage.clear();
      history.push("/login");
      window.location.reload();
    }
  };


  return (
    <CHeader>

      {mapFunctionallity === false ?
        <>
          {drawNav ? <ArrowBackIosRounded
            onClick={() => {
              history.goBack();
            }}
            style={{ marginLeft: 80, marginTop: 12, backgroundColor: "#75b7ff", borderRadius: 2, cursor: "pointer", fontSize: 30, padding: 5, color: "#000" }} /> : <></>}
          {!drawNav ?
            <CHeaderBrand >
              <CIcon name="logo" height="38" alt="Logo" />
            </CHeaderBrand> : <></>}
        </>
        : <>  </>}
      {/* <CLabel style={{ color: "#000" }}>{title}</CLabel> */}
      {mapFunctionallity === true ?
     
        <CHeaderNav className="d-md-down-none mr-auto" style={{ backgroundColor: "white", paddingLeft: 20, marginLeft: 50, paddingRight: 20, cursor: "pointer" }}>
           {isfttx == true ? <>
      </>: <> <CDropdown inNav className="c-header-nav-item mx-1">
            <CDropdownToggle className="c-header-nav-link">Switch Map</CDropdownToggle>
            <CDropdownMenu
              className="pt-0 dropdownHeader"
              style={{ backgroundColor: "#ffffff" }}
            >
              {PortData !== null ?
                PortData.device_items.map((data, i) => (
                  <CDropdownItem className="dropdownHeader" disabled={data.pivot.status === 1 ? false : true} onClick={() => {
                    history.push({
                      pathname: `/app/devices/${navigtionroutesname}/${portNumber}/maping/${data.pivot.id}`,
                      state: {
                        category_id: DeviceID
                      },
                    });
                    window.location.reload();
                  }} 
                  
                  >
                    <div className="flex-fill">
                      <CLabel style={{ float: "left", margin: 0 }}>Port No {i + 1}</CLabel>
                      {
                        data.pivot.status === 0 ? <CLabel style={{ float: "Right", fontSize: 10, fontWeight: "bolder", color: "gray" }}>Disable</CLabel> : <></>}
                    </div>
                  </CDropdownItem>
                )) : <></>}

            </CDropdownMenu>
          </CDropdown></>}
         
          {/* <CLabel>Selected Coupler</CLabel> */}
          {/* <CRow>
          <CTooltip content={"Unable Points"} placement="top" >
            <CLabel class="c-switch c-switch-primary" style={{ margin: 10, }}>
              <CSwitch
                shape='square'
                color="success" y
                onChange={(v) => {
                  //console.log(value.portEnable);
                  if (checkPolyLines === true) {
                    setcheckPolyLines(false);
                  } else {
                    setcheckPolyLines(true);
                  }
                }}
                checked={checkPolyLines}
              />

            </CLabel>
          </CTooltip>
        </CRow> */}
        </CHeaderNav> : ""}
      <CHeaderNav className="d-md-down-none mr-auto " >
        {/* {!drawNav ? <CLabel style={{ color: "#000", marginLeft: 20,fontSize:20,fontWeight:"bolder" }}>{title}</CLabel> : ""} */}
        {mapFunctionallity === true ? <h3 > {title} </h3> : ""}
      </CHeaderNav>
      <CHeaderNav className="px-1">
        {mapFunctionallity === true ? <CTooltip content={"Home"}>
          <Home className="iconHVR" onClick={() => {
            history.push({
              pathname: `/app/dashboard`,
            });
            window.location.reload();
          }} />
        </CTooltip>
          : ""}
        &nbsp;
        &nbsp;
        {isfttx ===true ? <></>: <>
        <CTooltip content={"Search Devices"}> 
        <SearchRounded onClick={onclickSerach} className="iconHVR" />
       </CTooltip> &nbsp;
        &nbsp;</>}
       

        {mapFunctionallity === true ? <CTooltip content={"Refresh Map"}>
          <Refresh className="iconHVR" onClick={() => {
            window.location.reload();
          }} />
        </CTooltip> : ""}
        &nbsp;
        &nbsp;
        {mapFunctionallity === true ? <CTooltip content={"Full Screen"}>

          <Fullscreen className="iconHVR" onClick={() => {
            var doc = window.document;
            var docEl = doc.documentElement;
            var doc = window.document;
            var docEl = doc.documentElement;

            var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
            var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

            if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
              requestFullScreen.call(docEl);
            }
            else {
              cancelFullScreen.call(doc);
            }
          }} /></CTooltip> : ""}
        &nbsp;
        &nbsp;
        {mapFunctionallity === true ? <CTooltip content={"Progress Report"}>
          <BarChart
            className="iconHVR"
          />
        </CTooltip> : <></>} &nbsp;
        &nbsp;
        {isfttx ===true ? <></>: <>
        {mapFunctionallity === true ? <TheHeaderShowCustomerList custCallFun={() => RefreshCustomerListFun()} portId={portID} /> : ""}
         </>}
        {mapFunctionallity === true ? <TheHeaderDropdownSetting /> : ""}
      
        &nbsp;&nbsp;
      
        {mapFunctionallity === true ? mapdevicename==="transmitter" ? <TransmitterMapTips />: <MapTips /> : ""}
        {isfttx ===true ? <></>: <>
        <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <div className="c-avatar">
              <CImg
                src={"https://igenerate.odhavtech.com/profile?text=" + firstName}
                className="c-avatar-img"
              // alt={UserData.email}
              />
            </div>
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem onClick={logout}>
              <CIcon name="cil-lock-locked" className="mfe-2" />
              Logout Account
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
        </>}
       
      </CHeaderNav>
    </CHeader>

  );
};

export default TheHeaderPlanScreen;
