import {
  CButton,
  CCol,
  CInput,
  CLabel,
  CCardBody,
  CRow,
  CCollapse,
  CCard,
  CDropdown,
  CCardHeader,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import InputMask from "react-input-mask";
import { MapApi } from "src/Config/API";
import { ArrowForward, Delete, Satellite } from "@material-ui/icons";
import FTTxIcon from "../../../../../../assets/mapsicons/FTTx.svg";
import { useHistory } from "react-router";

import L from "leaflet";
function CustomeFTTxshow({
  data,
  MapKey,
  mapDataFinal,
  handleImageView,
  DeleteDataByID,
  
  fttxModel,
  OncloseModal,
  getMasteData,
  forftth,
  match
}) 
{
  const [InputData, setInputData] = useState("");
  const [InputMeter1550, setInputMeter1550] = useState("");
  const [InputMeter1490, setInputMeter1490] = useState("");
  const [InputImage, setInputImage] = useState(null);
  const [EnableFTTXModal, setEnableFTTXModal] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setInputData(data.name);
    setInputMeter1490(data.loss_1490NM);
    setInputMeter1550(data.loss_1550NM);
  }, [data]);
  const [CustomerDetailsCollapse, setCustomerDetailsCollapse] = useState(false);

  const [DeviceDetailsCollapse, setDeviceDetailsCollapse] = useState(false);

  const handleDeviceDetailsCall = () => {
    setDeviceDetailsCollapse(!DeviceDetailsCollapse);
  };
  const handleCustomerDetailsColl = () => {
    setCustomerDetailsCollapse(!CustomerDetailsCollapse);
  };
  function EditFTTxById(
    id,
    InputData,
    InputImage,
  ) {
    const form = new FormData();
    form.append("name", InputData);
    if(InputImage!==null)
    {
    form.append("images", InputImage, InputImage.name);
    }
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(form);
  }

  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "/" +
            lat +
            "," +
            lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  return (
    <>
{forftth ?  <>

  <CCard>
          <CCardHeader> <center>
            <h4>
            <center>
            <h5>FTTx</h5>
          </center>
            </h4>
          </center></CCardHeader>
          <CCardBody>
          

          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>
                  <b>FTTx Name</b>
                </td>
                <td>
                  <CInput
                    onChange={(v) => setInputData(v.target.value)}
                    value={InputData}
                    placeholder="FTTx Name"
                  ></CInput>

                </td>
              </tr>{" "}
              <tr>
                <td>
                  <b>1550</b>
                  <div class="input-box-DB">
                    <CInput
                      onChange={(v) => setInputMeter1550(v.target.value)}
                      value={InputMeter1550}
                      placeholder="1550 power"
                    ></CInput>
                  </div>
                  <b>CATV</b>
                </td>
                {mapDataFinal.port.wdm_status === 1 ?
                <td>
                  <b>1490</b>
                  <div class="input-box-DB">
                    <CInput
                      onChange={(v) => setInputMeter1490(v.target.value)}
                      value={InputMeter1490}
                      placeholder="1490 power"
                    ></CInput>
                  </div>
                  <b>INTERNET</b>
                </td>
                :<></>}
              </tr>{" "}
              <tr>
                <td>
                  {" "}
                  <p
                    style={{
                      color: "black",
                      fontsize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    Upload Image{" "}
                    {data.images !== null ? (
                      <Satellite
                        onClick={
                          () => handleImageView()
                          //   handleImageView(
                          //     data.images
                          //   )
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </p>
                </td>
                <td>
                  <CInput
                    type="file"
                    placeholder=""
                    onChange={(v) => setInputImage(v.target.files[0])}
                  // value={InputImage}
                  ></CInput>
                </td>
              </tr>
              <tr>
                <td>
                  <CButton
                    onClick={() =>
                      EditFTTxById(
                        data.id,
                        InputData,
                        InputImage,
                      )
                    }
                    color="primary"
                    block
                  // variant="outline"
                  >
                    {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                    Save
                  </CButton></td>
                {/* <td><CButton
                  onClick={() => DeleteDataByID()}
                  color="info"
                  block
                  variant="outline"
                >
                  <Delete color="#ff0000" style={{ color: "red" }} />
                </CButton></td> */}
              </tr>
            </tbody>
          </table>
          <br />
         
            </CCardBody>
            </CCard>
         
       </> :   <Marker
        position={[parseFloat(data.latlang.lat), parseFloat(data.latlang.lang)]}
        icon={L.icon({
          iconUrl: FTTxIcon,
          iconRetinaUrl: FTTxIcon,
          iconAnchor: [5, 55],
          popupAnchor: [10, -44],
          iconSize: [60, 90],
        })}
      >
        <>
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputData}</center>

              <img
                src={data.images}
                width="200"
                height={"200"}
              />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputData}</center>
            </Tooltip>
          )}
        </>

        <Popup minWidth={300} closeButton={false}>
          <center>
            <h5>FTTx</h5>
          </center>

          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>
                  <b>FTTx Name</b>
                </td>
                <td>
                  <CInput
                    onChange={(v) => setInputData(v.target.value)}
                    value={InputData}
                    placeholder="FTTx Name"
                  ></CInput>

                </td>
              </tr>{" "}
              <tr>
                <td>
                  <b>1550</b>
                  <div class="input-box-DB">
                    <CInput
                      onChange={(v) => setInputMeter1550(v.target.value)}
                      value={InputMeter1550}
                      placeholder="1550 power"
                    ></CInput>
                  </div>
                  <b>CATV</b>
                </td>
              
                {mapDataFinal.port.wdm_status === 1 ?  <td>
                  <b>1490</b>
                  <div class="input-box-DB">
                    <CInput
                      onChange={(v) => setInputMeter1490(v.target.value)}
                      value={InputMeter1490}
                      placeholder="1490 power"
                    ></CInput>
                  </div>
                  <b>INTERNET</b>
                </td>
                :<></>}
              </tr>{" "}
              <tr>
                <td>
                  {" "}
                  <p
                    style={{
                      color: "black",
                      fontsize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    Upload Image{" "}
                    {data.images !== null ? (
                      <Satellite
                        onClick={
                          () => handleImageView()
                          //   handleImageView(
                          //     data.images
                          //   )
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </p>
                </td>
                <td>
                  <CInput
                    type="file"
                    placeholder=""
                    onChange={(v) => setInputImage(v.target.files[0])}
                  // value={InputImage}
                  ></CInput>
                </td>
              </tr>
              <tr>
                <td>
                  <CButton
                    onClick={() =>
                      EditFTTxById(
                        data.id,
                        InputData,
                        InputImage,
                      )
                    }
                    color="primary"
                    block
                  // variant="outline"
                  >
                    {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                    Save
                  </CButton></td>
                <td><CButton
                  onClick={() => DeleteDataByID()}
                  color="info"
                  block
                  variant="outline"
                >
                  <Delete color="#ff0000" style={{ color: "red" }} />
                </CButton></td>
              </tr>
            </tbody>
          </table>
          <CButton
            variant="outline"
            color="success"
            block
            onClick={() => {
              history.push({
                pathname: `/app/devices/${MapKey}/${match.params.port}/maping/${match.params.portnumber}/fttx/${data.id}`,
                state: {
                  mapapiKey: MapKey,
                  port: match.params.port,
                  portnumber: match.params.portnumber,
                  id: data.id,
                  category_id: match.params.id,
                  data: data
                },
              });
            }}
          >
            Draw To FTTx {">"}
          </CButton>

          <br />
          <CRow>
            <CCol>
              <CButton
                variant="outline"
                color="primary"
                block
                onClick={() => {
                  getMyLocation({
                    lat: parseFloat(mapDataFinal.latlang.lat),
                    lang: parseFloat(mapDataFinal.latlang.lang),
                  });
                  setLoadingModalShowForDirectionOpenMap(true);
                }}
              >
                {LoadingModalShowForDirectionOpenMap
                  ? "Wait Loading..."
                  : "Get Location"}{" "}
                {/* <ArrowForward /> */}
              </CButton>
              <br />
              {getlatforcurruntLocation !== "" ? (
                <center>
                  {" "}
                  <a
                    href={
                      "https://www.google.com/maps/dir/" +
                      getlatforcurruntLocation +
                      "," +
                      getlangforcurruntLocation +
                      "/" +
                      parseFloat(data.latlang.lat) +
                      "," +
                      parseFloat(data.latlang.lang)
                    }
                    target="_BLANK"
                  >
                    Get Directon <ArrowForward />
                  </a>
                </center>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
        </Popup>
      </Marker >}
     

    </>
  );
}

export default CustomeFTTxshow;
