import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    CCreateElement,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarNavDivider,
    CSidebarNavTitle,
    CSidebarMinimizer,
    CSidebarNavDropdown,
    CSidebarNavItem,
    CTooltip,
    CSwitch,
    CLabel,
} from "@coreui/react";
import jointIcon from "../../assets/mapsicons/joint.svg";
import loopIcon from "../../assets/mapsicons/loop.svg";
import onuIcon from "../../assets/mapsicons/onu.svg";
import orIcon from "../../assets/mapsicons/opticalrec.svg";
import orpIcon from "../../assets/mapsicons/orpassive.svg";
import fttxIcon from "../../assets/mapsicons/FTTx.png";
import ornIcon from "../../assets/mapsicons/orn.svg";
import Logo from "../../assets/logo1.png";
import LogoMini from "../../assets/minilogo.png";
import "../../views/style/style.css";
import { DeviceApi, MapApi } from "src/Config/API";
import { Home } from "@material-ui/icons";
const MapSidePanel = ({ mapFunctionallity,
    couplorfun,
    onufun,
    ornfun,
    plcfun,
    jointfun,
    loopfun,
    orfun,
    fttxfun,
    orpfun,
    checkPolyLines,
    setcheckPolyLines,
    cs,
    fttx,
    onu,
    orn,
    joints,
    ls,
    ors,
    orps,
    ps,
    polilineUnbleStatus,
    selectedDevice
}) => {
    const dispatch = useDispatch();
    const show = useSelector((state) => state.sidebarShow);
    const [nav, setnav] = useState([]);
    const actions = [
        { icon: MapApi.getCouplerIcon(0, 0, "row"), name: "Coupler", onClick: () => couplorfun(), img: { jointIcon }, show: cs },
        { icon: jointIcon, name: "Joint", onClick: () => jointfun(), img: { jointIcon }, show: joints },
        { icon: MapApi.getSplitterIcon(0, 0), name: "PLC Splitter", onClick: () => plcfun(), img: { jointIcon }, show: ps },
        { icon: loopIcon, name: "Loop", onClick: () => loopfun(), img: { jointIcon }, show: ls },
        { icon: onuIcon, name: "Onu", onClick: () => onufun(), img: { onufun }, show: onu },
        { icon: ornIcon, name: "Optical Reciver Node", onClick: () => ornfun(), img: { jointIcon }, show: orn },
        {
            icon: orIcon,
            name: "Optical Receiver Active",
            onClick: () => orfun()
            , img: { jointIcon }, show: ors
        },
        {
            icon: orpIcon,
            name: "Optical Receiver Passive",
            onClick: () => orpfun()
            , img: { jointIcon }, show: orps
        },
        {
            icon: fttxIcon,
            name: "FTTx",
            onClick: () => fttxfun()
            , img: { jointIcon }, show: fttx
        },
        // {
        //   icon: "MR",
        //   name: "Marker Enable Disable",
        //   onClick: () => mrfun(),
        // },
    ];
    return (
        <CSidebar
            show={show}
            onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
            style={{ backgroundColor: "white" }}
            unfoldable={false}
            fixed
            size={"sm"}
            className={"c-sidebar-minimized"}
        >
            <CSidebarBrand
                className="d-md-down-none"
            >
                <div className="c-sidebar-brand-full ">
                    <center>
                        <img src={Logo} className="imglogosidebar" />
                    </center>
                </div>
                <div
                    className=" c-sidebar-brand-minimized circleminilogo"
                    style={{ backgroundColor: "white" }}
                >
                    <img src={LogoMini} className="imglogosidebarmini" />
                </div>
            </CSidebarBrand>
            {polilineUnbleStatus ? <div class=""
                style={{ color: "#000000", borderRadius: 5, cursor: "pointer", backgroundColor: "#D9D9D9", paddingRight: 2, paddingTop: 5, paddingBottom: 5 }}
            >

                <CTooltip content={"Unable Points"} placement="top" >
                    <CLabel class="c-switch c-switch-primary" style={{ margin: 10, }}>
                        <CSwitch
                            shape='square'
                            color="success"
                            onChange={(v) => {
                                //console.log(value.portEnable);
                                if (checkPolyLines === true) {
                                    setcheckPolyLines(false);
                                } else {
                                    setcheckPolyLines(true);
                                }
                            }}
                            checked={checkPolyLines}
                        />
                    </CLabel>
                </CTooltip>
            </div> : <></>}


            {actions.map((data) => {
                return <>{
                    data.show ? <CTooltip content={data.name}>
                        <div class=""
                            style={{ margin: 4, cursor: "pointer", paddingRight: 2, paddingLeft: 2, paddingTop: 5, paddingBottom: 5,borderWidth:selectedDevice==data.name?2:0,borderColor:"black" ,borderStyle:"dashed"}}
                            onClick={() => data.onClick()}>
                            {/* <center>
                                <b>{data.icon}</b>
                            </center> */}
                            <img src={data.icon} style={{ width: "100%" }} />
                        </div>
                    </CTooltip> : <></>
                }</>
            })}
            {/* <CSidebarMinimizer className="c-d-md-down-none " /> */}
        </CSidebar>
    );
};

export default React.memo(MapSidePanel);
