import {
  CInput,
  CLabel,
  CRow,
  CCol,
  CButton,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CCard,
  CCardBody,
} from "@coreui/react";
import { Satellite, Delete, ArrowForward } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import loopIcon from "../../../../../../assets/mapsicons/loop.svg";
import { MapApi } from "src/Config/API";
import swal from 'sweetalert';
import { CardHeader } from "@material-ui/core";
export function CustomeLoopsShow({
  data,
  DeleteDataByID,
  mapDataFinal,
  getMasteData,
  PostPoliline,
  handleImageView,
  MapKey,
  PostPolilineContinue,
  forftth
}) {
  const [InputNo, setInputNo] = useState("");
  const [InputImage, setInputImage] = useState(null);
  const [InputMeter, setInputMeter] = useState("");
  useEffect(() => {
    setInputNo(data.name);
    setInputMeter(data.meters);
  }, [data]);

  function EditLoopsById(id, InputValue, InputImage, InputMeter) {
    console.log({
      Input: InputMeter,
    });
    const form = new FormData();
    form.append("name", InputValue);
    form.append("meters", InputMeter);

    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        swal("Saved!", "Information saved successfully!", "success");
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "/" +
            lat +
            "," +
            lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  return (
    <>

      {
        forftth ? <>
          <CCard>
            <CardHeader closeButton>
              <center>
                <h5>Loop</h5>
              </center>
            </CardHeader>
            <CCardBody>
              <CTabs activeTab="loss">
                <CTabContent>
                  <CTabPane data-tab="loss">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <b>Total Length </b>
                          </td>
                          <td>
                            {" "}
                            <div class="input-box-DB">
                              <CInput
                                onChange={(v) => setInputMeter(v.target.value)}
                                value={InputMeter}
                                placeholder="Enter Length"
                              ></CInput>
                              <span class="prefix">Length</span>
                            </div>
                          </td>
                        </tr>{" "}
                        {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                          <></>
                        ) : (
                          <>
                            <CLabel style={{ margin: 10, color: "blue" }}>
                              CATV
                            </CLabel>
                            <tr style={{ fontSize: 11 }}>
                              <td>
                                <center>
                                  <b>
                                    1550NM
                                    <br />
                                  </b>
                                  Optical Power
                                </center>
                              </td>
                              <td>
                                {" "}
                                <div class="input-box-DB">
                                  <CInput
                                    value={data.loss_1550NM}
                                  // placeholder="Joint Number"
                                  // mask="99.99"
                                  ></CInput>
                                  <span class="prefix">DB</span>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                        {mapDataFinal.port.wdm_status === 1 ? (
                          <>
                            <CLabel style={{ margin: 10, color: "blue" }}>
                              INTERNET
                            </CLabel>

                            <tr style={{ fontSize: 11 }}>
                              <td>
                                {" "}
                                <center>
                                  <b>
                                    1490NM
                                    <br />
                                  </b>
                                  Optical Power
                                </center>
                              </td>
                              <td>
                                {" "}
                                <div class="input-box-DB">
                                  <CInput
                                    value={data.loss_1490NM}
                                  // placeholder="Joint Number"
                                  // mask="99.99"
                                  ></CInput>
                                  <span class="prefix">DB</span>
                                </div>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </CTabPane>
                  <CTabPane data-tab="other_info">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <b>Loop No</b>
                          </td>
                          <td>
                            {" "}
                            <CInput
                              onChange={(v) => setInputNo(v.target.value)}
                              value={InputNo}
                              placeholder="Enter Loop No"
                            ></CInput>
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>
                            {" "}
                            <p
                              style={{
                                color: "black",
                                fontsize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Upload Image{" "}
                              {data.images !== null ? (
                                <Satellite
                                  onClick={
                                    () => handleImageView()
                                    // handleImageView(
                                    //   data.images
                                    // )
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </p>
                          </td>
                          <td>
                            {" "}
                            <CInput
                              type="file"
                              placeholder=""
                              onChange={(v) => setInputImage(v.target.files[0])}
                            // value={InputImage}
                            ></CInput>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CTabPane>
                </CTabContent>
                <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td>   <CButton
                      onClick={() =>
                        EditLoopsById(data.id, InputNo, InputImage, InputMeter)
                      }
                      color="primary"
                      block
                    // variant="outline"
                    >
                      {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                      Save{" "}
                    </CButton></td>
                    <td> <CButton
                      onClick={() => DeleteDataByID()}
                      color="info"
                      block
                      variant="outline"
                    >
                      <Delete color="#ff0000" style={{ color: "red" }} />
                    </CButton></td>
                  </tr>
                </tbody>
              </table>
                <CNav variant="tabs" justified="center">
                  <CNavItem>
                    <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                      Loss{" "}
                    </CNavLink>
                  </CNavItem>

                  <CNavItem>
                    <CNavLink
                      data-tab="other_info"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Other Info
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CTabs>
             

              {data.images !== null ?   <CButton color="primary" block style={{marginTop:10}} onClick={()=>handleImageView()}>
                  Show Image
                    &nbsp;<Satellite
                      onClick={
                        () => handleImageView()
                        // handleImageView(
                        //   data.images
                        // )
                      }
                    />
                
                </CButton>
                : 
                  <></> }
            </CCardBody>
          </CCard>
        </> : <>  <Marker
          position={[parseFloat(data.latlang.lat), parseFloat(data.latlang.lang)]}
          icon={L.icon({
            iconUrl: loopIcon,
            iconRetinaUrl: loopIcon,
            iconAnchor: [5, 55],
            popupAnchor: [10, -44],
            iconSize: [60, 90],
          })}
        >
          <>
            {data.images !== null ? (
              <Tooltip sticky>
                <center>{InputNo}</center>

                <img
                  src={data.images}
                  width="200"
                  height={"200"}
                />
              </Tooltip>
            ) : (
              <Tooltip sticky>
                <center>{InputNo}</center>
              </Tooltip>
            )}
          </>
          <Popup minWidth={300} closeButton={false}>
            <center>
              <h5>Loop</h5>
            </center>

            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Total Length </b>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              onChange={(v) => setInputMeter(v.target.value)}
                              value={InputMeter}
                              placeholder="Enter Length"
                            ></CInput>
                            <span class="prefix">Length</span>
                          </div>
                        </td>
                      </tr>{" "}
                      {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                        <></>
                      ) : (
                        <>
                          <CLabel style={{ margin: 10, color: "blue" }}>
                            CATV
                          </CLabel>
                          <tr style={{ fontSize: 11 }}>
                            <td>
                              <center>
                                <b>
                                  1550NM
                                  <br />
                                </b>
                                Optical Power
                              </center>
                            </td>
                            <td>
                              {" "}
                              <div class="input-box-DB">
                                <CInput
                                  value={data.loss_1550NM}
                                // placeholder="Joint Number"
                                // mask="99.99"
                                ></CInput>
                                <span class="prefix">DB</span>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                      {mapDataFinal.port.wdm_status === 1 ? (
                        <>
                          <CLabel style={{ margin: 10, color: "blue" }}>
                            INTERNET
                          </CLabel>

                          <tr style={{ fontSize: 11 }}>
                            <td>
                              {" "}
                              <center>
                                <b>
                                  1490NM
                                  <br />
                                </b>
                                Optical Power
                              </center>
                            </td>
                            <td>
                              {" "}
                              <div class="input-box-DB">
                                <CInput
                                  value={data.loss_1490NM}
                                // placeholder="Joint Number"
                                // mask="99.99"
                                ></CInput>
                                <span class="prefix">DB</span>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </CTabPane>
                <CTabPane data-tab="other_info">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>Loop No</b>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            onChange={(v) => setInputNo(v.target.value)}
                            value={InputNo}
                            placeholder="Enter Loop No"
                          ></CInput>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Upload Image{" "}
                            {data.images !== null ? (
                              <Satellite
                                onClick={
                                  () => handleImageView()
                                  // handleImageView(
                                  //   data.images
                                  // )
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            type="file"
                            placeholder=""
                            onChange={(v) => setInputImage(v.target.files[0])}
                          // value={InputImage}
                          ></CInput>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CTabPane>
              </CTabContent>
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                    Loss{" "}
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>
            <table class="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td>   <CButton
                    onClick={() =>
                      EditLoopsById(data.id, InputNo, InputImage, InputMeter)
                    }
                    color="primary"
                    block
                  // variant="outline"
                  >
                    {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                    Save{" "}
                  </CButton></td>
                  <td> <CButton
                    onClick={() => DeleteDataByID()}
                    color="info"
                    block
                    variant="outline"
                  >
                    <Delete color="#ff0000" style={{ color: "red" }} />
                  </CButton></td>
                </tr>

                <tr>
                  <td> {data.status === "Disable" ? (
                    <CButton color="primary" block disabled>
                      {/* <img src={drawpoliicon} height="20" /> */}
                      Add FiberLine
                      {/* <ShowChartRounded /> */}
                    </CButton>
                  ) : data.polyline_id !== null ? (
                    <CButton
                      color="primary"
                      block
                      onClick={() => {
                        PostPolilineContinue();
                        // closePopups();
                      }}
                    >
                      FiberLine Continue
                      {/* <img src={drawpoliicon} height="20" /> */}
                    </CButton>
                  ) : (
                    <CButton
                      variant="outline"
                      color="primary"
                      block
                      onClick={
                        () => PostPoliline()
                        // PostPoliline(
                        //   data.id,
                        //   parseFloat(data.latlang.lat),
                        //   parseFloat(data.latlang.lang)
                        // )
                      }
                    >
                      Add FiberLine
                    </CButton>
                  )}</td>
                  <td> <CCol>
                    <CButton
                      variant="outline"
                      color="primary"
                      block
                      onClick={() => {
                        getMyLocation({
                          lat: parseFloat(mapDataFinal.latlang.lat),
                          lang: parseFloat(mapDataFinal.latlang.lang),
                        });
                        setLoadingModalShowForDirectionOpenMap(true);
                      }}
                    >
                      {LoadingModalShowForDirectionOpenMap
                        ? "Wait Loading..."
                        : "Get Location"}{" "}
                      {/* <ArrowForward /> */}
                    </CButton>
                    <br />
                    {getlatforcurruntLocation !== "" ? (
                      <center>
                        {" "}
                        <a
                          href={
                            "https://www.google.com/maps/dir/" +
                            getlatforcurruntLocation +
                            "," +
                            getlangforcurruntLocation +
                            "/" +
                            parseFloat(data.latlang.lat) +
                            "," +
                            parseFloat(data.latlang.lang)
                          }
                          target="_BLANK"
                        >
                          Get Directon <ArrowForward />
                        </a>
                      </center>
                    ) : (
                      ""
                    )}
                  </CCol></td>
                </tr>
              </tbody>
            </table>
          </Popup>
        </Marker></>
      }

    </>
  );
}
