import {
  CButton,
  CCol,
  CInput,
  CLabel,
  CCardBody,
  CRow,
  CCollapse,
  CCard,
  CDropdown,
  CCardHeader,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import InputMask from "react-input-mask";
import { MapApi } from "src/Config/API";
import { ArrowForward, Delete, Satellite } from "@material-ui/icons";
import onuIcon from "../../../../../../assets/mapsicons/onu.svg";

import L from "leaflet";
import swal from "sweetalert";
function CustomeONUshow({
  data,
  MapKey,
  mapDataFinal,
  handleImageView,
  DeleteDataByID,
  OncloseModal,
  getMasteData,
  forftth
}) {
  const [InputData, setInputData] = useState("");
  const [InputMeter, setInputMeter] = useState("");
  const [Distance, setDistance] = useState("");
  const [CustomerName, setCustomerName] = useState(null);
  const [CustomerMobileNumber, setCustomerMobileNumber] = useState(null);
  const [CustomerAddress, setCustomerAddress] = useState(null);
  const [InputMeterRX, setInputMeterRX] = useState(null);
  const [InputONUCompany, setInputONUCompany] = useState(null);
  const [InputMacID, setInputMacID] = useState(null);
  const [InputONUType, setInputONUType] = useState(null);
  const [InputImage, setInputImage] = useState(null);
  const [InputSwapCheck, setInputSwapCheck] = useState(false);
  const [ONUIcons, setONUIcons] = useState([]);
  const [SelectINUIConsId, setSelectINUIConsId] = useState(0);
  useEffect(() => {
    MapApi.getONUIcons()
      .then((response) => {
        setONUIcons(response.data.icons);
      })
      .catch((error) => { });
    setInputData(data.name);
    if (data.onudata !== null) {
      setSelectINUIConsId(
        data.onudata.icon_id !== null ? data.onudata.icon_id : 0
      );
      setDistance(data.onudata.distance);
      setInputMeter(data.onudata.power_tx);
      setCustomerAddress(data.onudata.address);
      setCustomerName(data.onudata.customer_name);
      setCustomerMobileNumber(data.onudata.customer_number);
      setInputONUCompany(data.onudata.company_name);
      setInputMacID(data.onudata.mac_id);
      setInputMeterRX(data.onudata.power_rx);
    }
  }, [data]);
  const [CustomerDetailsCollapse, setCustomerDetailsCollapse] = useState(false);

  const [DeviceDetailsCollapse, setDeviceDetailsCollapse] = useState(false);

  const handleDeviceDetailsCall = () => {
    setDeviceDetailsCollapse(!DeviceDetailsCollapse);
  };
  const handleCustomerDetailsColl = () => {
    setCustomerDetailsCollapse(!CustomerDetailsCollapse);
  };
  function EditONUById(
    id,
    InputONUCompany,
    InputONUType,
    InputMacID,
    InputMeterRX,
    CustomerName,
    CustomerMobileNumber,
    CustomerAddress,
    InputData,
    InputImage,
    InputMeter,
    SelectINUIConsId
  ) {
    var data = {
      name: InputData,
      data: {
        company_name: InputONUCompany,
        mac_id: InputMacID,
        power_tx: InputMeter,
        customer_name: CustomerName,
        customer_number: CustomerMobileNumber,
        address: CustomerAddress,
        type: InputONUType,
        icon_id: SelectINUIConsId,
      },
    };
    const form = new FormData();

    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        swal("Saved!", "Information saved successfully!", "success");
        getMasteData();
        OncloseModal();
      })
      .catch((error) => {
        console.log(error);
      });
    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
      MapApi.UpdateMasterDataById(id, form, MapKey)
        .then((rsponse) => {
        swal("Saved!", "Information saved successfully!", "success");

          console.log(rsponse.data);
          getMasteData();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    console.log(form);
  }

  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "/" +
            lat +
            "," +
            lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  return (
    <>

      {forftth ? <>

        <CCard>

          <CCardHeader> <center>
            <h5>ONU</h5>
          </center></CCardHeader>
          <CCardBody>
            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                        <></>
                      ) : (
                        <>
                          <tr>
                            <td>
                              {" "}
                              <CLabel style={{ margin: 10, color: "blue" }}>
                                CATV
                              </CLabel>
                            </td>
                            {mapDataFinal.port.wdm_status === 1 ? (
                              <td>
                                <CLabel style={{ margin: 10, color: "blue" }}>
                                  INTERNET
                                </CLabel>
                              </td>
                            ) : (
                              <></>
                            )}
                          </tr>{" "}
                        </>
                      )}
                      <tr>
                        <td>
                          <center>
                            <b>
                              1310NM
                              <br />
                              Olt Receive Power
                            </b>
                          </center>
                          <div class="input-box-DB">
                            <CInput
                              // onChange={(v) => setInputMeterRX(v.target.value)}
                              value={InputMeterRX}
                              // placeholder="Joint Number"
                              mask="99.99"
                              readOnly
                              style={{ width: "100%" }}
                            // className="inputsm"
                            ></CInput>
                            <span class="prefix">DB</span>
                          </div>
                        </td>

                        {mapDataFinal.port.wdm_status === 1 ? (
                          <td>
                            <>
                              <center>
                                {" "}
                                <b>
                                  1490NM
                                  <br />
                                  Optical Power
                                </b>
                              </center>
                              <div class="input-box-DB">
                                <CInput
                                  value={data.loss_1490NM}
                                  readOnly
                                // placeholder="Joint Number"
                                // mask="99.99"
                                ></CInput>
                                <span class="prefix">DB</span>
                              </div>
                            </>
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      <tr>
                        <td>
                          <center>
                            <b>Power Tx</b>
                          </center>
                          <div class="input-box-DB">
                            <InputMask
                              onChange={(v) => setInputMeter(v.target.value)}
                              value={InputMeter}
                              // placeholder="Joint Number"
                              mask="99.99"
                              style={{ width: "100%" }}
                            // className="inputsm"
                            ></InputMask>
                            <span class="prefix">DB</span>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </CTabPane>

                <CTabPane data-tab="other_info">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <b>ONU Name</b>
                        </td>
                        <td>
                          <CInput
                            onChange={(v) => setInputData(v.target.value)}
                            value={InputData}
                            placeholder="ONU Number"
                          ></CInput>
                          {data.onudata.images !== "" ? (
                            <img
                              width={50}
                              height={50}
                              src={
                                "https://backend.navyatechs.com" +
                                data.onudata.images
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Upload Image{" "}
                            {data.images !== null ? (
                              <Satellite
                                onClick={
                                  () => handleImageView()
                                  //   handleImageView(
                                  //     data.images
                                  //   )
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                        <td>
                          <CInput
                            type="file"
                            placeholder=""
                            onChange={(v) => setInputImage(v.target.files[0])}
                          // value={InputImage}
                          ></CInput>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Select type
                          </p>
                        </td>
                        <td>
                          <CDropdown className="mt-0 w-`100`">
                            <select
                              caret
                              color="secondary"
                              className="w-100 h-20 custom-select-lg dropdowns"
                              // name="patientTypeRegister"
                              value={SelectINUIConsId}
                              onChange={(e) =>
                                setSelectINUIConsId(e.target.value)
                              }
                            >
                              {ONUIcons.map((data) => (
                                <option value={data.id} selected>
                                  {data.name}
                                </option>
                              ))}
                            </select>
                          </CDropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <CCard>
                    <CCardHeader
                      style={{
                        backgroundColor: "#3091ed",
                        color: "white",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleCustomerDetailsColl()}
                    >
                      Customer Details
                    </CCardHeader>
                    <CCollapse show={CustomerDetailsCollapse}>
                      <CCardBody>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <b>Customer Name</b>
                              </td>

                              <td>
                                <CInput
                                  onChange={(v) =>
                                    setCustomerName(v.target.value)
                                  }
                                  value={CustomerName}
                                  placeholder="Customer Name"
                                  style={{ width: "100%" }}
                                // className="inputsm"
                                ></CInput>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Mobile Number</b>
                              </td>
                              <td>
                                <CInput
                                  onChange={(v) =>
                                    setCustomerMobileNumber(v.target.value)
                                  }
                                  value={CustomerMobileNumber}
                                  placeholder="Mobile Number"
                                  style={{ width: "100%" }}
                                // className="inputsm"
                                ></CInput>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Customer Address</b>
                              </td>
                              <td>
                                {" "}
                                <CInput
                                  onChange={(v) =>
                                    setCustomerAddress(v.target.value)
                                  }
                                  value={CustomerAddress}
                                  // placeholder="Joint Number"
                                  placeholder="Address"
                                  style={{ width: "100%" }}
                                // className="inputsm"
                                ></CInput>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                  <CCard>
                    <CCardHeader
                      style={{
                        backgroundColor: "#3091ed",
                        color: "white",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleDeviceDetailsCall()}
                    >
                      Device Details
                    </CCardHeader>
                    <CCollapse show={DeviceDetailsCollapse}>
                      <CCardBody>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <b>ONU Company</b>
                              </td>
                              <td>
                                <CInput
                                  onChange={(v) =>
                                    setInputONUCompany(v.target.value)
                                  }
                                  value={InputONUCompany}
                                  placeholder="Comapany name"
                                  style={{ width: "100%" }}
                                // className="inputsm"
                                ></CInput>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>ONU MacID</b>
                              </td>
                              <td>
                                <CInput
                                  onChange={(v) => setInputMacID(v.target.value)}
                                  value={InputMacID}
                                  // placeholder="Joint Number"
                                  placeholder="Mac Id"
                                  style={{ width: "100%" }}
                                // className="inputsm"
                                ></CInput>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>ONU Type</b>
                              </td>
                              <td>
                                {" "}
                                <CInput
                                  onChange={(v) =>
                                    setInputONUType(v.target.value)
                                  }
                                  value={InputONUType}
                                  // placeholder="Joint Number"
                                  placeholder="ONU Type"
                                  style={{ width: "100%" }}
                                // className="inputsm"
                                ></CInput>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </CTabPane>
              </CTabContent>
              <div>
                <CRow>
                  <CCol>
                    <CButton
                      onClick={() =>
                        EditONUById(
                          data.id,
                          InputONUCompany,
                          InputONUType,
                          InputMacID,
                          InputMeterRX,
                          CustomerName,
                          CustomerMobileNumber,
                          CustomerAddress,
                          InputData,
                          InputImage,
                          InputMeter,
                          SelectINUIConsId
                        )
                      }
                      color="primary"
                      block
                    // variant="outline"
                    >
                      {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                      Save{" "}
                    </CButton>
                  </CCol>
                  <CCol>
                    <CButton
                      onClick={() => DeleteDataByID()}
                      color="info"
                      block
                      variant="outline"
                    >
                      <Delete color="#ff0000" style={{ color: "red" }} />
                    </CButton>
                  </CCol>
                </CRow>
              </div>
              <br />
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                    Loss{" "}
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Other Info
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>
          </CCardBody>
          {data.images !== null ?   <CButton color="primary" block style={{marginTop:10}} onClick={()=>handleImageView()}>
                  Show Image
                    &nbsp;<Satellite
                      onClick={
                        () => handleImageView()
                        // handleImageView(
                        //   data.images
                        // )
                      }
                    />
                
                </CButton>
                : 
                  <></> }
        </CCard>



      </> : <Marker
        position={[parseFloat(data.latlang.lat), parseFloat(data.latlang.lang)]}
        icon={L.icon({
          iconUrl: onuIcon,
          iconRetinaUrl: onuIcon,
          iconAnchor: [5, 55],
          popupAnchor: [10, -44],
          iconSize: [60, 90],
        })}
      >
        <>
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputData}</center>

              <img
                src={data.images}
                width="200"
                height={"200"}
              />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputData}</center>
            </Tooltip>
          )}
        </>

        <Popup minWidth={300} closeButton={false}>
          <center>
            <h5>ONU</h5>
          </center>

          <CTabs activeTab="loss">
            <CTabContent>
              <CTabPane data-tab="loss">
                <table class="table table-bordered table-sm">
                  <tbody>
                    {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                      <></>
                    ) : (
                      <>
                        <tr>
                          <td>
                            {" "}
                            <CLabel style={{ margin: 10, color: "blue" }}>
                              CATV
                            </CLabel>
                          </td>
                          {mapDataFinal.port.wdm_status === 1 ? (
                            <td>
                              <CLabel style={{ margin: 10, color: "blue" }}>
                                INTERNET
                              </CLabel>
                            </td>
                          ) : (
                            <></>
                          )}
                        </tr>{" "}
                      </>
                    )}
                    <tr>
                      <td>
                        <center>
                          <b>
                            1310NM
                            <br />
                            Olt Receive Power
                          </b>
                        </center>
                        <div class="input-box-DB">
                          <CInput
                            // onChange={(v) => setInputMeterRX(v.target.value)}
                            value={InputMeterRX}
                            // placeholder="Joint Number"
                            mask="99.99"
                            readOnly
                            style={{ width: "100%" }}
                          // className="inputsm"
                          ></CInput>
                          <span class="prefix">DB</span>
                        </div>
                      </td>

                      {mapDataFinal.port.wdm_status === 1 ? (
                        <td>
                          <>
                            <center>
                              {" "}
                              <b>
                                1490NM
                                <br />
                                Optical Power
                              </b>
                            </center>
                            <div class="input-box-DB">
                              <CInput
                                value={data.loss_1490NM}
                                readOnly
                              // placeholder="Joint Number"
                              // mask="99.99"
                              ></CInput>
                              <span class="prefix">DB</span>
                            </div>
                          </>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <center>
                          <b>Power Tx</b>
                        </center>
                        <div class="input-box-DB">
                          <InputMask
                            onChange={(v) => setInputMeter(v.target.value)}
                            value={InputMeter}
                            // placeholder="Joint Number"
                            mask="99.99"
                            style={{ width: "100%" }}
                          // className="inputsm"
                          ></InputMask>
                          <span class="prefix">DB</span>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </CTabPane>

              <CTabPane data-tab="other_info">
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <b>ONU Name</b>
                      </td>
                      <td>
                        <CInput
                          onChange={(v) => setInputData(v.target.value)}
                          value={InputData}
                          placeholder="ONU Number"
                        ></CInput>
                        {data.onudata.images !== "" ? (
                          <img
                            width={50}
                            height={50}
                            src={
                              "https://backend.navyatechs.com" +
                              data.onudata.images
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>
                        {" "}
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Upload Image{" "}
                          {data.images !== null ? (
                            <Satellite
                              onClick={
                                () => handleImageView()
                                //   handleImageView(
                                //     data.images
                                //   )
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </p>
                      </td>
                      <td>
                        <CInput
                          type="file"
                          placeholder=""
                          onChange={(v) => setInputImage(v.target.files[0])}
                        // value={InputImage}
                        ></CInput>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Select type
                        </p>
                      </td>
                      <td>
                        <CDropdown className="mt-0 w-`100`">
                          <select
                            caret
                            color="secondary"
                            className="w-100 h-20 custom-select-lg dropdowns"
                            // name="patientTypeRegister"
                            value={SelectINUIConsId}
                            onChange={(e) =>
                              setSelectINUIConsId(e.target.value)
                            }
                          >
                            {ONUIcons.map((data) => (
                              <option value={data.id} selected>
                                {data.name}
                              </option>
                            ))}
                          </select>
                        </CDropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <CCard>
                  <CCardHeader
                    style={{
                      backgroundColor: "#3091ed",
                      color: "white",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleCustomerDetailsColl()}
                  >
                    Customer Details
                  </CCardHeader>
                  <CCollapse show={CustomerDetailsCollapse}>
                    <CCardBody>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Customer Name</b>
                            </td>

                            <td>
                              <CInput
                                onChange={(v) =>
                                  setCustomerName(v.target.value)
                                }
                                value={CustomerName}
                                placeholder="Customer Name"
                                style={{ width: "100%" }}
                              // className="inputsm"
                              ></CInput>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Mobile Number</b>
                            </td>
                            <td>
                              <CInput
                                onChange={(v) =>
                                  setCustomerMobileNumber(v.target.value)
                                }
                                value={CustomerMobileNumber}
                                placeholder="Mobile Number"
                                style={{ width: "100%" }}
                              // className="inputsm"
                              ></CInput>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Customer Address</b>
                            </td>
                            <td>
                              {" "}
                              <CInput
                                onChange={(v) =>
                                  setCustomerAddress(v.target.value)
                                }
                                value={CustomerAddress}
                                // placeholder="Joint Number"
                                placeholder="Address"
                                style={{ width: "100%" }}
                              // className="inputsm"
                              ></CInput>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCardBody>
                  </CCollapse>
                </CCard>
                <CCard>
                  <CCardHeader
                    style={{
                      backgroundColor: "#3091ed",
                      color: "white",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleDeviceDetailsCall()}
                  >
                    Device Details
                  </CCardHeader>
                  <CCollapse show={DeviceDetailsCollapse}>
                    <CCardBody>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>ONU Company</b>
                            </td>
                            <td>
                              <CInput
                                onChange={(v) =>
                                  setInputONUCompany(v.target.value)
                                }
                                value={InputONUCompany}
                                placeholder="Comapany name"
                                style={{ width: "100%" }}
                              // className="inputsm"
                              ></CInput>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>ONU MacID</b>
                            </td>
                            <td>
                              <CInput
                                onChange={(v) => setInputMacID(v.target.value)}
                                value={InputMacID}
                                // placeholder="Joint Number"
                                placeholder="Mac Id"
                                style={{ width: "100%" }}
                              // className="inputsm"
                              ></CInput>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>ONU Type</b>
                            </td>
                            <td>
                              {" "}
                              <CInput
                                onChange={(v) =>
                                  setInputONUType(v.target.value)
                                }
                                value={InputONUType}
                                // placeholder="Joint Number"
                                placeholder="ONU Type"
                                style={{ width: "100%" }}
                              // className="inputsm"
                              ></CInput>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CTabPane>
            </CTabContent>
            <div>
              <CRow>
                <CCol>
                  <CButton
                    onClick={() =>
                      EditONUById(
                        data.id,
                        InputONUCompany,
                        InputONUType,
                        InputMacID,
                        InputMeterRX,
                        CustomerName,
                        CustomerMobileNumber,
                        CustomerAddress,
                        InputData,
                        InputImage,
                        InputMeter,
                        SelectINUIConsId
                      )
                    }
                    color="primary"
                    block
                  // variant="outline"
                  >
                    {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                    Save{" "}
                  </CButton>
                </CCol>
                <CCol>
                  <CButton
                    onClick={() => DeleteDataByID()}
                    color="info"
                    block
                    variant="outline"
                  >
                    <Delete color="#ff0000" style={{ color: "red" }} />
                  </CButton>
                </CCol>
              </CRow>
            </div>
            <br />
            <CNav variant="tabs" justified="center">
              <CNavItem>
                <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                  Loss{" "}
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  data-tab="other_info"
                  style={{ fontFamily: "sans-serif" }}
                >
                  Other Info
                </CNavLink>
              </CNavItem>
            </CNav>
          </CTabs>
          <br />
          <CRow>
            <CCol>
              <CButton
                variant="outline"
                color="primary"
                block
                onClick={() => {
                  getMyLocation({
                    lat: parseFloat(mapDataFinal.latlang.lat),
                    lang: parseFloat(mapDataFinal.latlang.lang),
                  });
                  setLoadingModalShowForDirectionOpenMap(true);
                }}
              >
                {LoadingModalShowForDirectionOpenMap
                  ? "Wait Loading..."
                  : "Get Location"}{" "}
                {/* <ArrowForward /> */}
              </CButton>
              <br />
              {getlatforcurruntLocation !== "" ? (
                <center>
                  {" "}
                  <a
                    href={
                      "https://www.google.com/maps/dir/" +
                      getlatforcurruntLocation +
                      "," +
                      getlangforcurruntLocation +
                      "/" +
                      parseFloat(data.latlang.lat) +
                      "," +
                      parseFloat(data.latlang.lang)
                    }
                    target="_BLANK"
                  >
                    Get Directon <ArrowForward />
                  </a>
                </center>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
        </Popup>
      </Marker>}

    </>
  );
}

export default CustomeONUshow;
