import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CLink,
  CLabel,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

// routes config
import routes from "../routes";

import {
  TheHeaderDropdown,

  TheHeaderDropdownSetting,
  TheHeaderDropDownDevices,
} from "./index";
import { Icon } from "@material-ui/core";
import { PaymentOutlined, Subscriptions, SubscriptionsRounded } from "@material-ui/icons";

const TheHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem>
          <CHeaderNavLink to="/app/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
        <TheHeaderDropDownDevices />
        <CHeaderNavItem className="d-md-down-none mr-auto">
          <CHeaderNavLink to="/app/reports">Reports</CHeaderNavLink>
        </CHeaderNavItem>
        {/* <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/app/draws">New Draws</CHeaderNavLink>
        </CHeaderNavItem> */}
        <CHeaderNavItem className="d-md-down-none mr-auto">
          <CHeaderNavLink to="/app/informations">Information</CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>
      <CHeaderNav className="px-3">
      <CHeaderNavItem className="d-md-down-none mr-auto">
          <CHeaderNavLink to="/app/subscription"><PaymentOutlined style={{color:"orange"}}/> &nbsp; <CLabel style={{color:"orange",marginTop:8}}>Subscription</CLabel></CHeaderNavLink>
        </CHeaderNavItem>
        {/* <TheHeaderDropdownSetting /> */}
        <TheHeaderDropdown />
      </CHeaderNav>
      
      <CSubheader className="px-3 justify-content-between">
        
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
        <div className="d-md-down-none mfe-2 c-subheader-nav">
        </div>
      </CSubheader>
    </CHeader>
  );
};

export default TheHeader;
